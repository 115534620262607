<template>
  <div class="container-fluid">
    <div class="page-header d-flex justify-content-between align-items-center">
      <strong>ค่ายเกม</strong>
      <button class="btn btn-sm btn-link" @click="add">เพิ่ม</button>
    </div>

    <div class="card">
      <div class="card-header">
        <ul class="nav nav-tabs card-header-tabs">
          <li v-for="cat in categories" :key="cat._id" class="nav-item">
            <a class="nav-link" :class="[{'active':catId===cat._id}]" href="#" target="_self" @click="catId=cat._id">{{ cat.name }}</a>
          </li>
        </ul>
      </div>

      <div>
        <table class="table table-striped table-hover mb-0">
          <thead>
            <tr>
              <th width="15%">ลำดับ</th>
              <th class="p-1">ค่ายเกม</th>
              <th class="p-1">ProductId</th>
              <th width="20%" class="p-1">สถานะ</th>
              <th class="p-1"></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in items">
              <td>{{ item.sort }}</td>
              <td>{{ item.name }}</td>
              <td>{{ item.productId }}</td>
              <td>{{ item.status | creStatus }}</td>
              <td class="text-right">
                <button class="btn btn-sm btn-outline-info" @click="edit(item)"><i class="far fa-edit"></i></button>
              </td>
            </tr>
            <tr v-if="!items.length">
              <td colspan="5" class="text-center">ไม่มีข้อมูล</td>
            </tr>
          </tbody>
        </table>
      </div>

    </div>

    <GameCreatorModal :data="input" :cats="categories" :is-show="isShowModal" @close="modalClosed" />
  </div>
</template>
<script>
import GameService from '@/services/gameService'
import GameCreatorModal from './components/GameCreatorModal'

export default {
  name: 'Creators',
  components: {
    GameCreatorModal
  },
  data() {
    return {
      data: null,
      catId: null,
      isShowModal: false,
      input: {
        _id: null,
        catId: null,
        name: null,
        productId: null,
        autoAddGame: false,
        sort: 0,
        status: 'Active'
      }
    }
  },
  computed: {
    items() {
      return (this.data?.creators ?? []).filter((creator)=>{
        return creator.catId === this.catId
      })
    },
    categories() {
      return this.data?.categories ?? []
    }
  },
  methods: {
    loadData() {
      GameService.getCreators().then((response)=>{
        if(response.success) {
          this.data = response.data

          if(response.data?.categories?.length && !this.catId) {
            this.catId = response.data.categories[0]._id
          }
        }
      })
    },
    add() {
      this.input = {
        _id: null,
        catId: null,
        name: null,
        productId: null,
        autoAddGame: false,
        sort: 0,
        status: 'Active'
      }
      this.isShowModal = true
    },
    edit(item) {
      this.input = {
        _id: item._id,
        catId: item.catId,
        name: item.name,
        productId: item.productId,
        autoAddGame: false,
        sort: item.sort,
        status: item.status
      }
      this.isShowModal = true
    },
    modalClosed(needReload) {
      this.isShowModal = false
      if(needReload) {
        this.loadData()
      }
    }
  },
  mounted() {
    this.loadData()
  },
  filters: {
    creStatus(val) {
      return {
        Active: 'ใช้งาน',
        Inactive: 'ไม่ใช้งาน'
      }[val]
    }
  }
}
</script>
<style lang="scss" scoped>
.table {
  th {
    border-top: 0px;
    vertical-align: middle;
  }

  td {
    vertical-align: middle;

    img {
      height: 60px;
    }
  }
}
</style>
